import React from "react";

export default function ContentItem({ imgSrc, imgAlt, title, text }) {
  return (
    <div className="content-item">
      <img src={imgSrc} alt={imgAlt} className="content-item__img" />
      <div>
        <h5 className="content-item__title">{title}</h5>
        <p className="content-item__text">{text}</p>
      </div>
    </div>
  );
}
