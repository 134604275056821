import React from "react";
import EcoStoreLogo from "../../assets/new/eco-store/eco-store-logo.png";
import { ecoStoreComments, ecoStoreData } from "../../utils/constants";
import EcoStoreMobileImg from "../../assets/new/eco-store/eco-store-moile.png";
import "./index.css";

// TODO: This file is a first step in orgranising this code base.
// Initially everything was just dumped into Landing/index.js which made it very hard to maintain.
// In the near future, we want to simplify this code and make this component responsive with media queries
// as opposed to using isMobileView to keep our code DRY.

export default function EcoStore({
  ecoStoreRef,
  logoActive,
  onGetStarted,
  isMobileView,
}) {
  return isMobileView ? (
    <div
      className="C-Eco-Full-Section eco-store-mobile-container"
      ref={ecoStoreRef}
    >
      <div className="eco-store-logo">
        <img src={EcoStoreLogo} alt="eco-store-logo" />
        <svg
          className={`eco-title-logo ${logoActive.store ? "active" : ""}`}
          width="128"
          height="21"
          viewBox="0 0 128 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.52165 11.4802C2.56798 9.38976 6.49172 7.77841 8.32279 7.23403C16.6934 4.62107 30.1649 3.31446 35.7889 3.96776C41.4129 4.62107 35.2658 5.92747 29.9033 7.23398C24.5409 8.54048 28.4646 9.19374 33.3039 8.54048C38.1432 7.88723 48.2141 7.23398 56.8463 6.90735C65.4785 6.58072 56.7155 5.92747 48.2141 5.92747C39.7127 5.92747 39.8434 4.62102 42.8516 3.96776C45.8598 3.31451 52.661 0.701497 60.6392 1.02812C68.6175 1.35475 87.4514 2.008 95.5605 4.29439C103.67 6.58078 120.411 12.7867 124.465 14.7464C128.52 16.7062 127.997 22.2589 120.803 18.9926C113.61 15.7263 98.6995 9.84704 66.0017 8.86716C33.3039 7.88728 8.45356 13.4399 5.83774 15.7263C3.22192 18.0127 1.39084 18.0127 2.43717 16.0529C3.4835 14.0932 3.7451 13.7666 2.69877 14.4198C1.65244 15.0731 0.213739 14.0932 1.52165 11.4802Z"
            fill="#743AEC"
            stroke="#743AEC"
            className="svg-elem-1"
          ></path>
        </svg>
      </div>
      <h2 className="eco-title eco-store__heading poppins-bold">
        How do we increase <br />
        your sales?
      </h2>
      <div className="eco-store-card">
        <div className="eco-store-card-subtitle">Gamified Shopping</div>
        <div className="eco-store-card-text">
          Your PS2 safe, pop up digital marketplace for subscriptions, quick
          buys and group splitting.
        </div>
        <div className="eco-store-card-text">
          Make it fun and compelling for visitors to purchase, share with
          friends and give their feedback.
        </div>
        <button
          className="primary-btn btn-create poppins-bold"
          onClick={onGetStarted}
        >
          Create your Ecosystem
        </button>
        {ecoStoreComments.map((item, index) => (
          <img
            src={item}
            alt={`comment-${index}`}
            className="eco-comment-image"
            key={index}
          />
        ))}
      </div>
      <div className="eco-store-content">
        {ecoStoreData.map((item) => (
          <div className="eco-store-item" key={item.title}>
            <img
              src={item.icon}
              className="eco-store-icon"
              alt={`${item.title}-icon`}
            />
            <div className="eco-store-title poppins-semibold">
              {item.mobileTitle}
            </div>
            <div className="eco-store-description">{item.description}</div>
          </div>
        ))}
      </div>
      <div className="eco-store-right">
        <img src={EcoStoreMobileImg} alt="eco mobile img" />
      </div>
    </div>
  ) : (
    <div className="C-Eco-Full-Section eco-store-container" ref={ecoStoreRef}>
      <div className="eco-store-col-content">
        <div className="eco-store__heading section-title poppins-bold">
          <div className="eco-store-logo">
            <img src={EcoStoreLogo} alt="eco-store-logo" />
            <svg
              className={`eco-title-logo ${logoActive.store ? "active" : ""}`}
              width="128"
              height="21"
              viewBox="0 0 128 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.52165 11.4802C2.56798 9.38976 6.49172 7.77841 8.32279 7.23403C16.6934 4.62107 30.1649 3.31446 35.7889 3.96776C41.4129 4.62107 35.2658 5.92747 29.9033 7.23398C24.5409 8.54048 28.4646 9.19374 33.3039 8.54048C38.1432 7.88723 48.2141 7.23398 56.8463 6.90735C65.4785 6.58072 56.7155 5.92747 48.2141 5.92747C39.7127 5.92747 39.8434 4.62102 42.8516 3.96776C45.8598 3.31451 52.661 0.701497 60.6392 1.02812C68.6175 1.35475 87.4514 2.008 95.5605 4.29439C103.67 6.58078 120.411 12.7867 124.465 14.7464C128.52 16.7062 127.997 22.2589 120.803 18.9926C113.61 15.7263 98.6995 9.84704 66.0017 8.86716C33.3039 7.88728 8.45356 13.4399 5.83774 15.7263C3.22192 18.0127 1.39084 18.0127 2.43717 16.0529C3.4835 14.0932 3.7451 13.7666 2.69877 14.4198C1.65244 15.0731 0.213739 14.0932 1.52165 11.4802Z"
                fill="#743AEC"
                stroke="#743AEC"
                className="svg-elem-1"
              ></path>
            </svg>
          </div>
          <h2 className="section-title eco-chat__heading poppins-bold">
            This is e-commerce 2.0 via organic social growth.
          </h2>
        </div>
        <div className="eco-store-bottom-content">
          <div className="eco-store-card">
            <div className="eco-store-card-subtitle">Gamified Shopping</div>
            <div className="eco-store-card-text">
              Your PS2 safe, pop up digital marketplace for subscriptions, quick
              buys and group splitting.
            </div>
            <div className="eco-store-card-text">
              Make it fun and compelling for visitors to purchase, share with
              friends and give their feedback.
            </div>
            <button
              className="primary-btn btn-create poppins-bold"
              onClick={onGetStarted}
            >
              Create your Ecosystem
            </button>
            {ecoStoreComments.map((item, index) => (
              <img
                src={item}
                alt={`comment-${index}`}
                className="eco-comment-image"
                key={index}
              />
            ))}
          </div>
          <div className="eco-store-content">
            {ecoStoreData.map((item) => (
              <div className="eco-store-item" key={item.title}>
                <img
                  src={item.icon}
                  className="eco-store-icon"
                  alt={`${item.title}-icon`}
                />
                <div className="eco-store-item-content">
                  <div className="eco-store-title poppins-semibold">
                    {item.title}
                  </div>
                  <div className="eco-store-description">
                    {item.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="eco-store-right">
            <img src={EcoStoreMobileImg} alt="eco mobile img" />
          </div>
        </div>
      </div>
    </div>
  );
}
