import EcoIdMobileImg from "../../assets/new/eco-id/eco-id-mobile.png";
import EcoIdQuoteImg from "../../assets/new/eco-id/eco-id-quote.png";
import EcoIdLogo from "../../assets/new/eco-id/eco-id-logo.png";
import EcoIdContentImg from "../../assets/new/eco-id/eco-id-content.png";

// TODO: This file is a first step in orgranising this code base.
// Initially everything was just dumped into Landing/index.js which made it very hard to maintain.
// In the near future, we want to simplify this code and make this component responsive with media queries
// as opposed to using isMobileView to keep our code DRY.

// TODO: where is the mobile part?
export default function EcoID({ isMobileView, logoActive }) {
  return isMobileView ? null : (
    <div className="eco-id-container">
      <div className="eco-id-left">
        <div className="eco-id-top">
          <div className="eco-id-logo">
            <img src={EcoIdLogo} alt="eco id logo" />
            <svg
              className={`eco-title-logo ${logoActive.id ? "active" : ""}`}
              width="76"
              height="14"
              viewBox="0 0 76 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.810507 7.61906C1.43332 6.2988 3.76888 5.2811 4.8588 4.93728C9.84134 3.28699 17.8601 2.46176 21.2077 2.87438C24.5553 3.28699 20.8963 4.11209 17.7044 4.93725C14.5124 5.76241 16.848 6.17499 19.7285 5.76241C22.609 5.34983 28.6036 4.93725 33.7418 4.73096C38.8801 4.52467 33.664 4.11209 28.6036 4.11209C23.5432 4.11209 23.6211 3.28696 25.4117 2.87438C27.2023 2.4618 31.2506 0.811472 35.9995 1.01776C40.7485 1.22405 51.9592 1.63663 56.786 3.08067C61.6128 4.5247 71.5779 8.44422 73.9913 9.68196C76.4047 10.9197 76.0933 14.4266 71.8114 12.3637C67.5296 10.3008 58.6544 6.58761 39.1915 5.96873C19.7285 5.34986 4.93665 8.8568 3.37961 10.3008C1.82257 11.7449 0.732643 11.7449 1.35546 10.5071C1.97827 9.26938 2.13399 9.06309 1.51117 9.47567C0.888358 9.88825 0.0319877 9.26938 0.810507 7.61906Z"
                fill="#743AEC"
                stroke="#743AEC"
                className="svg-elem-1"
              ></path>
            </svg>
          </div>
          <img
            src={EcoIdQuoteImg}
            className="eco-id-quote"
            alt="eco id quote"
          />
        </div>

        <div className="eco-id-content">
          <img src={EcoIdContentImg} alt="eco i content" />
        </div>
      </div>
      <div className="eco-id-right">
        <img src={EcoIdMobileImg} alt="eco card mobile" />
      </div>
    </div>
  );
}
