import React from "react";
import { NavLink } from "react-router-dom";
import "./index.scss";

import EcosystemTMBlack from "../../assets/new/ecosystem_tm_black.png";

const renderLogoImg = (active) => (
  <svg
    className={`eco-title-logo ${active ? "active" : ""}`}
    width="128"
    height="21"
    viewBox="0 0 128 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.52165 11.4802C2.56798 9.38976 6.49172 7.77841 8.32279 7.23403C16.6934 4.62107 30.1649 3.31446 35.7889 3.96776C41.4129 4.62107 35.2658 5.92747 29.9033 7.23398C24.5409 8.54048 28.4646 9.19374 33.3039 8.54048C38.1432 7.88723 48.2141 7.23398 56.8463 6.90735C65.4785 6.58072 56.7155 5.92747 48.2141 5.92747C39.7127 5.92747 39.8434 4.62102 42.8516 3.96776C45.8598 3.31451 52.661 0.701497 60.6392 1.02812C68.6175 1.35475 87.4514 2.008 95.5605 4.29439C103.67 6.58078 120.411 12.7867 124.465 14.7464C128.52 16.7062 127.997 22.2589 120.803 18.9926C113.61 15.7263 98.6995 9.84704 66.0017 8.86716C33.3039 7.88728 8.45356 13.4399 5.83774 15.7263C3.22192 18.0127 1.39084 18.0127 2.43717 16.0529C3.4835 14.0932 3.7451 13.7666 2.69877 14.4198C1.65244 15.0731 0.213739 14.0932 1.52165 11.4802Z"
      fill="#743AEC"
      stroke="#743AEC"
      className="svg-elem-1"
    ></path>
  </svg>
);

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: -1,
      menuVisible: false,
      isScrolled: false,
    };
    this.outOfMenu = React.createRef();
  }
  componentDidMount() {
    this.setState({});
    // FIXME: close on outside click interferes with section scroll feature
    // document.addEventListener("mousedown", this.handleClick, true);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, true);
    window.removeEventListener("scroll", this.handleScroll);
  }
  toggleMenu = () => {
    this.setState((prevState) => ({ menuVisible: !prevState.menuVisible }));
  };
  click = () => {
    this.setState({ menuVisible: false });
  };
  clickul = () => {
    this.setState({ menuVisible: false });
  };
  //  start of when click the outsite of menu
  handleClickOutSide() {
    this.setState({ menuVisible: false });
  }
  handleClick = (e) => {
    if (!this.outOfMenu.current.contains(e.target)) {
      this.handleClickOutSide();
    } else {
      return;
    }
  };
  handleScroll = () => {
    if (window.scrollY > 0) {
      this.setState({ isScrolled: true });
    } else {
      this.setState({ isScrolled: false });
    }
  };

  handleLogoClick = () => {
    this.setState({ tabIndex: -1 });
    window.scrollTo(0, 0);
  };

  render() {
    const { menuVisible, tabIndex, isScrolled } = this.state;
    const { onClickTab } = this.props;

    return (
      <header id="page-header" className={isScrolled ? "scrolled-header" : ""}>
        <div className="content-header">
          <div className="heading">
            <div className="d-flex justify-content-center">
              <button className="header-logo" onClick={this.handleLogoClick}>
                <img src={EcosystemTMBlack} height="40px" alt="logo" />
              </button>
              <button
                className="header-ecosystem"
                onClick={this.handleLogoClick}
              ></button>
            </div>
            <ul
              className={`header__list ${menuVisible ? "show" : "hide"}`}
              onClick={this.clickul}
              ref={this.outOfMenu}
            >
              <li className="nav-main-item profile">
                <a
                  href="https://brands.ecosystem.co.uk/signup"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-main-link"
                >
                  <span className="nav-main-link-name">
                    Create an Ecosystem
                  </span>
                </a>
              </li>
              <li className="nav-main-item profile">
                <a
                  href="https://brands.ecosystem.co.uk/"
                  target="_blank"
                  rel="noreferrer"
                  className="nav-main-link"
                >
                  <span className="nav-main-link-name">
                    Log into your Ecosystem
                  </span>
                </a>
              </li>
              <li className="nav-main-item">
                <button
                  type="button"
                  className={tabIndex === 0 ? "nav-main-link" : "nav-main-link"}
                  onClick={() => {
                    onClickTab(0);
                    this.setState({ tabIndex: 0 });
                  }}
                >
                  <span className="nav-main-link-name">
                    <b>Social</b> Tools
                  </span>
                  {renderLogoImg(!menuVisible && tabIndex === 0)}
                </button>
              </li>
              <li className="nav-main-item">
                <button
                  type="button"
                  className={tabIndex === 1 ? "nav-main-link" : "nav-main-link"}
                  onClick={() => {
                    onClickTab(1);
                    this.setState({ tabIndex: 1 });
                  }}
                >
                  <span className="nav-main-link-name">
                    <b>Chat</b> Tools
                  </span>
                  {renderLogoImg(!menuVisible && tabIndex === 1)}
                </button>
              </li>
              <li className="nav-main-item">
                <button
                  type="button"
                  className={tabIndex === 2 ? "nav-main-link" : "nav-main-link"}
                  onClick={() => {
                    onClickTab(2);
                    this.setState({ tabIndex: 2 });
                  }}
                >
                  <span className="nav-main-link-name">
                    <b>Marketplace</b> Tools
                  </span>
                  {renderLogoImg(!menuVisible && tabIndex === 2)}
                </button>
              </li>
              <li className="nav-main-item">
                <button
                  type="button"
                  className={tabIndex === 3 ? "nav-main-link" : "nav-main-link"}
                  onClick={() => {
                    onClickTab(3);
                    this.setState({ tabIndex: 3 });
                  }}
                >
                  <span className="nav-main-link-name">
                    <b>Gamification</b> Tools
                  </span>
                  {renderLogoImg(!menuVisible && tabIndex === 3)}
                </button>
              </li>
              <li className="nav-main-item">
                <button
                  type="button"
                  className={tabIndex === 4 ? "nav-main-link" : "nav-main-link"}
                  onClick={() => {
                    onClickTab(4);
                    this.setState({ tabIndex: 4 });
                  }}
                >
                  <span className="nav-main-link-name">
                    <b>Referral</b> Tools
                  </span>
                  {renderLogoImg(!menuVisible && tabIndex === 4)}
                </button>
              </li>
              <li className="nav-main-item">
                <NavLink
                  to="/magazine"
                  className={tabIndex === 5 ? "nav-main-link" : "nav-main-link"}
                >
                  <span className="nav-main-link-name">About Us</span>
                </NavLink>
              </li>
            </ul>
            <button
              className="burger"
              onClick={() => this.toggleMenu()}
              ref={this.outOfMenu}
            >
              <div className="burger__line" />
              <div className="burger__line" />
            </button>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
