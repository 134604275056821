import React, { Fragment } from "react";
import ContentItem from "./ContentItem";
import EcoReferralsLogo from "../../assets/new/eco-referral/eco-referrals-logo.png";
import ReferralPhoneImg from "../../assets/new/eco-referral/eco-referral-mobile.png";
import EcoReferralsItems from "../../assets/new/eco-referral/eco-referral-litems.png";
import EcoReferralsImg from "../../assets/new/eco-referral/eco-referral-mobile.png";
import EcoReferralsMobileTurquiose from "../../assets/new/eco-referral/referrals-bg-turquoise.png";
import { REFERRALS_CONTENT } from "../../utils/constants";
import "./index.css";

// TODO: This file is a first step in orgranising this code base.
// Initially everything was just dumped into Landing/index.js which made it very hard to maintain.
// In the near future, we want to simplify this code and make this component responsive with media queries
// as opposed to using isMobileView to keep our code DRY.

export default function EcoReferrals({
  ecoReferralsRef,
  logoActive,
  isMobileView,
}) {
  return isMobileView ? (
    <div
      className="C-Eco-Full-Section eco-referrals-mobile-container"
      ref={ecoReferralsRef}
    >
      <img
        src={EcoReferralsMobileTurquiose}
        alt="eco referrals mobile bg"
        className="eco-referrals-mobile-bg"
      />
      <div className="eco-referrals-logo">
        <img src={EcoReferralsLogo} alt="eco referrals logo" />
        <svg
          className={`eco-title-logo ${logoActive ? "active" : ""}`}
          width="216"
          height="21"
          viewBox="0 0 216 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.88598 11.4802C3.66308 9.38976 10.3272 7.77841 13.4371 7.23403C27.6539 4.62107 50.534 3.31446 60.0859 3.96776C69.6378 4.62107 59.1974 5.92747 50.0898 7.23398C40.9821 8.54048 47.6462 9.19374 55.8653 8.54048C64.0844 7.88723 81.189 7.23398 95.8501 6.90735C110.511 6.58072 95.6279 5.92747 81.189 5.92747C66.7501 5.92747 66.9722 4.62102 72.0814 3.96776C77.1905 3.31451 88.7417 0.701497 102.292 1.02812C115.842 1.35475 147.83 2.008 161.603 4.29439C175.375 6.58078 203.809 12.7867 210.695 14.7464C217.581 16.7062 216.693 22.2589 204.475 18.9926C192.258 15.7263 166.934 9.84704 111.4 8.86716C55.8653 7.88728 13.6592 13.4399 9.21648 15.7263C4.77373 18.0127 1.66381 18.0127 3.44091 16.0529C5.21801 14.0932 5.66231 13.7666 3.88522 14.4198C2.10812 15.0731 -0.335395 14.0932 1.88598 11.4802Z"
            fill="#743AEC"
            stroke="#743AEC"
            className="svg-elem-1"
          ></path>
        </svg>
      </div>
      <div className="eco-title eco-title-top poppins-bold">
        Making sharing
        <br /> rewarding
      </div>
      <img
        src={EcoReferralsItems}
        className="eco-referrals-items"
        alt="eco referrals item"
      />
      <div className="eco-referrals-content">
        {REFERRALS_CONTENT.map((item, i) => (
          <div className="eco-referrals-item" key={i}>
            <img
              className="eco-referrals-icon"
              src={item.imgSrc}
              alt={item.imgAlt}
            />
            <div className="eco-referrals-title poppins-semibold">
              {item.title}
            </div>
            <div className="eco-referrals-description">{item.text}</div>
          </div>
        ))}
      </div>
      <div className="referrals__mobile-sub-title eco-title-share">
        Share articles, offers, <br className="hide-on-desktop" />
        access, achievements <br className="hide-on-desktop" />
        and points socially
      </div>
      <img
        src={EcoReferralsImg}
        className="eco-double-mobile-image"
        alt="eco double mobile"
      />
    </div>
  ) : (
    <section className="referrals" ref={ecoReferralsRef}>
      <svg
        className="referrals__top-wave"
        width="2800"
        height="557"
        viewBox="0 0 2075 411"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M623.393 36.6971C371.563 88.1722 166.441 27.8713 98.1055 0.000213623V151.433H2074.63C2055.48 107.048 1965.97 60.1083 1877.67 40.8213C1778.2 19.0939 1654.98 23.5886 1543.11 51.9996C1318.44 109.064 1187.31 87.3297 1021.57 48.3102C940.352 24.6197 777.927 5.11 623.393 36.6971Z"
          fill="#cdfffb"
        />
        <path
          d="M1545.92 246.902C1797.75 195.427 1991.14 382.315 2059.48 410.186L2019.33 151.433L40.8169 99.4194L0 181.72C19.1582 226.105 134.31 266.169 289.698 266.169C405.577 266.169 615.836 187.391 728.843 163.943C922.407 123.78 1008.84 227.15 1174.59 266.169C1255.8 289.86 1391.39 278.489 1545.92 246.902Z"
          fill="#cdfffb"
        />
      </svg>
      <div className="referrals__top-container">
        <div className="referrals__logo-container">
          <img
            src={EcoReferralsLogo}
            alt="EcoReferrals Logo"
            className="referrals__logo"
          />
          <svg
            className={`eco-title-logo ${logoActive.referrals ? "active" : ""}`}
            width="178"
            height="22"
            viewBox="0 0 178 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.72866 12.0318C3.19019 9.83133 8.67097 8.13517 11.2287 7.56214C22.921 4.81165 41.7383 3.43627 49.594 4.12396C57.4498 4.81165 48.8633 6.18681 41.3729 7.56208C33.8825 8.93735 39.3633 9.62498 46.1229 8.93735C52.8825 8.24971 66.9498 7.56208 79.0075 7.21826C91.0652 6.87444 78.8248 6.18681 66.9498 6.18681C55.0748 6.18681 55.2575 4.8116 59.4594 4.12396C63.6614 3.43633 73.1614 0.685786 84.3056 1.0296C95.4498 1.37342 121.758 2.06106 133.084 4.46778C144.411 6.8745 167.796 13.407 173.46 15.4699C179.123 17.5328 178.392 23.3777 168.344 19.9396C158.296 16.5014 137.469 10.3127 91.796 9.28122C46.1229 8.24977 11.4113 14.0947 7.75748 16.5014C4.10363 18.9081 1.54594 18.9081 3.00748 16.8452C4.46902 14.7823 4.83443 14.4385 3.37289 15.1261C1.91135 15.8138 -0.098269 14.7823 1.72866 12.0318Z"
              fill="#743AEC"
              stroke="#743AEC"
              className="svg-elem-1"
            ></path>
          </svg>
        </div>
        <h2 className="referrals__heading poppins-bold">
          Rewarding referrals drive organic growth.
        </h2>
      </div>

      <h3 className="referrals__sub-title">
        Your community seed growth by sharing insider access,
        <br className="referrals--show-on-desktop-sm" /> offers, articles,
        achievements, points & love socially.
      </h3>

      <div className="referrals__container">
        <div className="referrals__img-container">
          <img
            src={ReferralPhoneImg}
            alt="Referral phone"
            className="referrals__img"
          />
        </div>
        <article className="referrals__content">
          <div className="referrals__content-container">
            {REFERRALS_CONTENT.map((item, i) => (
              <Fragment key={i}>
                <ContentItem
                  imgSrc={item.imgSrc}
                  imgAlt={item.imgAlt}
                  title={item.title}
                  text={item.text}
                />
              </Fragment>
            ))}
          </div>
        </article>
      </div>
    </section>
  );
}
