import React from "react";
import EcoFactBgImg from "../../assets/new/eco-factbg.png";
import EcoBoost1Icon from "../../assets/new/eco-boost/Icon-1.png";
import EcoBoost2Icon from "../../assets/new/eco-boost/Icon.png";
import EcoBoostMobileImg from "../../assets/new/eco-boost-mobile.png";
import "./index.css";

// TODO: This file is a first step in orgranising this code base.
// Initially everything was just dumped into Landing/index.js which made it very hard to maintain.
// In the near future, we want to simplify this code and make this component responsive with media queries
// as opposed to using isMobileView to keep our code DRY.

export default function EcoBoost({
  selectedBoostTab,
  setSelectedBoostTab,
  isMobileView,
}) {
  return isMobileView ? (
    <div
      className="C-Eco-Full-Section eco-boost-mobile-container"
      style={{ zIndex: 10 }}
    >
      <img className="eco-boost-bg" src={EcoFactBgImg} alt="eco fact img"></img>
      <div className="C-Eco-Full-Content">
        <div
          className={`eco-boost-card ${selectedBoostTab === 0 ? "active" : ""}`}
          onClick={() => setSelectedBoostTab(0)}
        >
          <div className="eco-boost-card-icon">
            <img src={EcoBoost1Icon} alt="eco boost first card icon" />
          </div>
          <div className="eco-boost-card-title poppins-semibold">Engage</div>
          <div className="eco-boost-card-description">
            Reward members with points for
            <br /> all valued actions, to spend with
            <br /> you or 100s of retailers.
            <br />
            <br /> Your branded program builds
            <br />
            engagement, loyalty and
            <br /> conversion through high value
            <br /> daily touch points.
          </div>
        </div>
        <div
          className={`eco-boost-card ${selectedBoostTab === 1 ? "active" : ""}`}
          onClick={() => setSelectedBoostTab(1)}
        >
          <div className="eco-boost-card-icon">
            <img src={EcoBoost2Icon} alt="eco boost first card icon" />
          </div>
          <div className="eco-boost-card-title poppins-semibold">Expand</div>
          <div className="eco-boost-card-description">
            Our tools help you scale through
            <br /> social networks of existing
            <br />
            customers, as they share
            <br /> content.
            <br />
            <br /> Through your happy members,
            <br />
            you'll both retain and refer,
            <br /> driving more optimal return
            <br /> on your growth spends.
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="Div-Ecosystem">
      <h2 className="eco-boost__heading section-title poppins-bold">
        Super-boosting your retention and growth
      </h2>
      <div className="eco-boost-cards-container">
        <div
          className={`eco-boost-card ${selectedBoostTab === 0 ? "active" : ""}`}
          onClick={() => setSelectedBoostTab(0)}
        >
          <div className="eco-boost-card-icon">
            <img src={EcoBoost1Icon} alt="eco boost first card icon" />
          </div>
          <div className="eco-boost-card-title poppins-semibold">Engage</div>
          <div className="eco-boost-card-description">
            Reward members with points for all
            <br /> valued actions, to spend with you or
            <br /> 100s of retailers.
            <br />
            <br /> Your branded program builds
            <br /> engagement, loyalty and conversion
            <br /> through high value daily touch points.
            <br />
          </div>
        </div>
        <img
          className="eco-boost-mobile"
          src={EcoBoostMobileImg}
          alt="eco boost mobile"
        />
        <div
          className={`eco-boost-card ${selectedBoostTab === 1 ? "active" : ""}`}
          onClick={() => setSelectedBoostTab(1)}
        >
          <div className="eco-boost-card-icon">
            <img src={EcoBoost2Icon} alt="eco boost first card icon" />
          </div>
          <div className="eco-boost-card-title poppins-semibold">Expand</div>
          <div className="eco-boost-card-description">
            Our tools help you scale through
            <br /> social networks of existing customers,
            <br /> as they share content.
            <br />
            <br /> Through your happy members, you'll
            <br /> both retain and refer, driving more
            <br /> optimal return on your growth spends.
          </div>
        </div>
      </div>
    </div>
  );
}
