import React from "react";
import EcoChatLogoDark from "../../assets/new/ecochat/eco-chat-logo-dark.png";
import EcoChatLeft from "../../assets/new/eco-chat-left.png";
import EcoChatBot from "../../assets/new/ecochat/chat-bot.gif";
import EcoChatGroup from "../../assets/new/ecochat/chat-group.gif";
import EcoChatRoute from "../../assets/new/ecochat/chat-route.gif";
import "./index.css";

// TODO: This file is a first step in orgranising this code base.
// Initially everything was just dumped into Landing/index.js which made it very hard to maintain.
// In the near future, we want to simplify this code and make this component responsive with media queries
// as opposed to using isMobileView to keep our code DRY.

export default function EcoChat({ ecoChatRef, logoActive, isMobileView }) {
  return isMobileView ? (
    <div
      className="C-Eco-Full-Section eco-chat-mobile-container"
      ref={ecoChatRef}
    >
      <div className="eco-chat-logo">
        <img src={EcoChatLogoDark} alt="eco chat logo" />
        <svg
          className={`eco-title-logo ${logoActive.chat ? "active" : ""}`}
          width="128"
          height="21"
          viewBox="0 0 128 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.52165 11.4802C2.56798 9.38976 6.49172 7.77841 8.32279 7.23403C16.6934 4.62107 30.1649 3.31446 35.7889 3.96776C41.4129 4.62107 35.2658 5.92747 29.9033 7.23398C24.5409 8.54048 28.4646 9.19374 33.3039 8.54048C38.1432 7.88723 48.2141 7.23398 56.8463 6.90735C65.4785 6.58072 56.7155 5.92747 48.2141 5.92747C39.7127 5.92747 39.8434 4.62102 42.8516 3.96776C45.8598 3.31451 52.661 0.701497 60.6392 1.02812C68.6175 1.35475 87.4514 2.008 95.5605 4.29439C103.67 6.58078 120.411 12.7867 124.465 14.7464C128.52 16.7062 127.997 22.2589 120.803 18.9926C113.61 15.7263 98.6995 9.84704 66.0017 8.86716C33.3039 7.88728 8.45356 13.4399 5.83774 15.7263C3.22192 18.0127 1.39084 18.0127 2.43717 16.0529C3.4835 14.0932 3.7451 13.7666 2.69877 14.4198C1.65244 15.0731 0.213739 14.0932 1.52165 11.4802Z"
            fill="#743AEC"
            stroke="#743AEC"
            className="svg-elem-1"
          ></path>
        </svg>
      </div>
      <div className="eco-chat-heading poppins-bold">
        Whatsapp on acid. <br className="hide-on-desktop" />
        WeChat for the West.
      </div>
      <div className="eco-chat-container">
        <div className="eco-chat-left">
          <img src={EcoChatLeft} alt="eco chat left" />
        </div>
        <div className="eco-chat-right">
          <div className="eco-chat-item">
            <img
              className="eco-chat-image"
              src={EcoChatBot}
              alt="eco chat bot"
            />
            <div className="eco-chat-content">
              <div className="eco-chat-title poppins-semibold">Chat bots</div>
              <div className="eco-chat-description poppins-regular">
                Swiftly build customised chatbots,
                <br /> without coding, driving auto comms <br /> towards
                products, articles, games, polls, <br /> surveys, verification
                and creativity.
              </div>
            </div>
          </div>
          <div className="eco-chat-item">
            <img
              className="eco-chat-image"
              src={EcoChatRoute}
              alt="eco chat bot"
            />
            <div className="eco-chat-content">
              <div className="eco-chat-title poppins-semibold">
                Chat routing and SLAs
              </div>
              <div className="eco-chat-description poppins-regular">
                Customer help desk chat and tickets
                <br /> provide cross channel interaction.
                <br /> Align your comms to goals, nurture
                <br /> relationships and save costs.
              </div>
            </div>
          </div>
          <div className="eco-chat-item">
            <img
              className="eco-chat-image"
              src={EcoChatGroup}
              alt="eco chat bot"
            />
            <div className="eco-chat-content">
              <div className="eco-chat-title poppins-semibold">Group Chat</div>
              <div className="eco-chat-description poppins-regular">
                Whatsapp and Facebook doesn't cut <br />
                it for comms in your org, so we've <br /> created a social chat
                platform to <br /> cultivate private engagement within <br />{" "}
                your groups.
                <br />
                <br />
                Groups can; create events, fund stuff,
                <br /> split costs, run polls, host live stream
                <br /> meets, plan goals, and help friends <br />
                hit goals. Unlock time and finance <br />
                savings.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="C-EcoChat" ref={ecoChatRef}>
      <div className="eco-chat__heading-container">
        <div className="eco-chat-logo">
          <img src={EcoChatLogoDark} alt="eco chat logo" />
          <svg
            className={`eco-title-logo ${logoActive.chat ? "active" : ""}`}
            width="128"
            height="21"
            viewBox="0 0 128 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.52165 11.4802C2.56798 9.38976 6.49172 7.77841 8.32279 7.23403C16.6934 4.62107 30.1649 3.31446 35.7889 3.96776C41.4129 4.62107 35.2658 5.92747 29.9033 7.23398C24.5409 8.54048 28.4646 9.19374 33.3039 8.54048C38.1432 7.88723 48.2141 7.23398 56.8463 6.90735C65.4785 6.58072 56.7155 5.92747 48.2141 5.92747C39.7127 5.92747 39.8434 4.62102 42.8516 3.96776C45.8598 3.31451 52.661 0.701497 60.6392 1.02812C68.6175 1.35475 87.4514 2.008 95.5605 4.29439C103.67 6.58078 120.411 12.7867 124.465 14.7464C128.52 16.7062 127.997 22.2589 120.803 18.9926C113.61 15.7263 98.6995 9.84704 66.0017 8.86716C33.3039 7.88728 8.45356 13.4399 5.83774 15.7263C3.22192 18.0127 1.39084 18.0127 2.43717 16.0529C3.4835 14.0932 3.7451 13.7666 2.69877 14.4198C1.65244 15.0731 0.213739 14.0932 1.52165 11.4802Z"
              fill="#743AEC"
              stroke="#743AEC"
              className="svg-elem-1"
            ></path>
          </svg>
        </div>
        <h2 className="section-title eco-chat__heading poppins-bold">
          Whatsapp on acid. WeChat for the West.
        </h2>
      </div>
      <div className="eco-chat-container">
        <div className="eco-chat-left">
          <img src={EcoChatLeft} alt="eco chat left" />
        </div>
        <div className="eco-chat-right">
          <div className="eco-chat-item">
            <img
              className="eco-chat-image"
              src={EcoChatBot}
              alt="eco chat bot"
            />
            <div className="eco-chat-content">
              <div className="eco-chat-title poppins-semibold">Chat bots</div>
              <div className="eco-chat-description poppins-regular">
                Swiftly build customised chatbots without coding,
                <br />
                driving auto comms towards products, articles,
                <br /> games, polls, surveys, verification and creativity.
              </div>
            </div>
          </div>
          <div className="eco-chat-item">
            <img
              className="eco-chat-image"
              src={EcoChatRoute}
              alt="eco chat bot"
            />
            <div className="eco-chat-content">
              <div className="eco-chat-title poppins-semibold">
                Chat routing and SLAs
              </div>
              <div className="eco-chat-description poppins-regular">
                Customer help desk chat and tickets provide cross
                <br /> channel interaction. Align your comms to goals,
                <br /> nurture relationships and save costs.
              </div>
            </div>
          </div>
          <div className="eco-chat-item">
            <img
              className="eco-chat-image"
              src={EcoChatGroup}
              alt="eco chat bot"
            />
            <div className="eco-chat-content">
              <div className="eco-chat-title poppins-semibold">Group Chat</div>
              <div className="eco-chat-description poppins-regular">
                Whatsapp and Facebook doesn't cut it for comms in
                <br /> your org, so we've created a social chat platform to
                <br />
                cultivate private engagement within your groups.
                <br />
                <br /> Groups can; create events, fund stuff, split costs, run
                <br /> polls, host live stream meets, plan goals, and help
                <br />
                friends hit goals. Unlock time and finance savings.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
